// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-getaquote-js": () => import("./../../../src/pages/getaquote.js" /* webpackChunkName: "component---src-pages-getaquote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-service-commercial-cleaning-js": () => import("./../../../src/pages/service-commercial-cleaning.js" /* webpackChunkName: "component---src-pages-service-commercial-cleaning-js" */),
  "component---src-pages-service-domestic-cleaning-js": () => import("./../../../src/pages/service-domestic-cleaning.js" /* webpackChunkName: "component---src-pages-service-domestic-cleaning-js" */),
  "component---src-pages-service-end-of-tenancy-js": () => import("./../../../src/pages/service-end-of-tenancy.js" /* webpackChunkName: "component---src-pages-service-end-of-tenancy-js" */),
  "component---src-pages-service-maid-training-js": () => import("./../../../src/pages/service-maid-training.js" /* webpackChunkName: "component---src-pages-service-maid-training-js" */),
  "component---src-pages-service-material-cleaning-js": () => import("./../../../src/pages/service-material-cleaning.js" /* webpackChunkName: "component---src-pages-service-material-cleaning-js" */),
  "component---src-pages-service-mobile-car-wash-js": () => import("./../../../src/pages/service-mobile-car-wash.js" /* webpackChunkName: "component---src-pages-service-mobile-car-wash-js" */),
  "component---src-pages-service-overnight-baby-sitting-js": () => import("./../../../src/pages/service-overnight-baby-sitting.js" /* webpackChunkName: "component---src-pages-service-overnight-baby-sitting-js" */),
  "component---src-pages-service-post-construction-js": () => import("./../../../src/pages/service-post-construction.js" /* webpackChunkName: "component---src-pages-service-post-construction-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

